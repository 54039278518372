import React from "react";
import { RainbowMenu } from "../components/RainbowMenu";
import { Sidebar } from "../components/Sidebar";
import { StaticImage } from "gatsby-plugin-image";
import { LogOpen } from "../components/LogOpen";

import "./styles.scss"

export default function PostsPage() {

  return <div>
    <LogOpen page="newsletter" />
    <RainbowMenu active="Newsletter" relativePath="../"></RainbowMenu>

    <div className="columns">
      <div className="column is-3">
        <Sidebar></Sidebar>
      </div>
      <div className="mt-4">
        <h2 className="title is-4">Newsletter</h2>
        <p className="mb-3">
          Hier kannst du dich für unsere
          Mailingliste anmelden und wir informieren dich regelmäßig über
          Veranstaltungen und Ereignisse.
        </p>
        <form action="http://kundenserver.de/cgi-bin/mailinglist.cgi" method="post" target="_blank" rel="noreferrer">
          <div className="field">
          </div>
          <div className="field">
            <label className="label">
              Deine E-Mail-Adresse
            </label>
            <div className="columns">
              <div className="column">
                <div className="control">
                  <input type="text" size="51" name="mailaccount_r" maxLength="51" className="input" placeholder="z.B. max@mustermann.de" />
                </div>
              </div>
              <div className="column">
                <div className="control mb-1">
                  <div className="select">
                    <select name="subscribe_r">
                      <option value="subscribe" checked="checked">In Newsletter eintragen</option>
                      <option value="unsubscribe">Aus Newsletter austragen</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label">
              Wiederholung
            </label>
            <div className="control">
              <input type="text" size="51" name="mailaccount2_r" maxLength="51" className="input" placeholder="z.B. max@mustermann.de" />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <input type="submit" className="button is-primary" value="abschicken" />
            </div>
          </div>
          <input type="hidden" name="FBMLNAME" value="smag-gruppe@fliederlich.de" />
          <input type="hidden" name="FBLANG" value="de" />
          <input type="hidden" name="FBURLERROR_L" value="http://kundenserver.de/mailinglist/error.de.html" />
          <input type="hidden" name="FBURLSUBSCRIBE_L" value="http://kundenserver.de/mailinglist/subscribe.de.html" />
          <input type="hidden" name="FBURLUNSUBSCRIBE_L" value="http://kundenserver.de/mailinglist/unsubscribe.de.html" />
          <input type="hidden" name="FBURLINVALID_L" value="http://kundenserver.de/mailinglist/invalid.de.html" />
        </form>
      </div>
    </div>
  </div>;
}